<template>
  <article class="sub1_b_bg">
    <h1>연혁</h1>
    <ul class="gnb">
      <router-link to="/sub1_a" exact-active-class="active" tag="li">회사소개</router-link>
      <router-link to="/sub1_b" exact-active-class="active" tag="li" class="on">연혁</router-link>
      <router-link to="/sub1_c" exact-active-class="active" tag="li">구성원</router-link>
      <router-link to="/sub1_d" exact-active-class="active" tag="li">오시는 길</router-link>
    </ul>
    <section class="sub1_b">
      <div class="line">
        <p> </p>
        <p> </p>
        <p> </p>
        <p> </p>
        <p> </p>
        <p> </p>
      </div>
      <div class="year">
        <div class="year1">
          <h2>2022</h2>
          <div class="content">
            <p><strong>04</strong>∘ 특허 등록 증강현실과 GIS를 이용한 전력 사용량 모니터링 시스템</strong></p>
          </div>
        </div>
        <div class="year2">
          <h2>2021</h2>
          <div class="content">
            <p>∘ 직접 생산증명 갱신 (7개 항목-중소기업중앙회)<strong>04</strong></p>
            <p>　　　∘ 소프트웨어 사업자 갱신 (한국 소프트웨어 산업 협회)</p>
            <p>　　　∘ 창업기업 확인 (중소벤처기업부)</p>
            <p>∘ 기술 이전 계약 (한국전력공사)<strong>09</strong></p>
            <p>∘ 연구개발전담부서 인정 (한국산업기술진흥협회)<strong>10</strong></p>
            <p>∘ 특허 권리 양수(이종 시스템간 메시지 통신 중계장침 및 방법 외 1건, 한국전력공사)<strong>11</strong></p>
          </div>
        </div>
        <div class="year3">
          <h2>2020</h2>
          <div class="content">
            <p><strong>11</strong>∘ 본사 이전 (세종시 나성동)</p>
            <p><strong>10</strong>∘ 기술 이전 계약 (한국전력공사)</p>
            <p>- 스마트시티 에너지 설비의 통합 정보 제공 장치 및 방법　　　</p>
            <p><strong>06</strong>∘ 공로 감사패 수령 (한국전력공사)</p>
          </div>
        </div>
        <div class="year4">
          <h2>2019</h2>
          <div class="content">
            <p>∘ 직접 생산증명 갱신 (7개 항목-중소기업중앙회)<strong>11</strong></p>
            <p>∘ 소프트웨어 사업자 갱신 (한국 소프트웨어 산업 협회)<strong>05</strong></p>
          </div>
        </div>
        <div class="year5">
          <h2>2018</h2>
          <div class="content">
            <p><strong>07</strong>∘ 한전 KDN 협력업체 등록</p>
            <p><strong>05</strong>∘ 소프트웨어 사업자 등록 (한국 소프트웨어 산업 협회)</p>
          </div>
        </div>
        <div class="year6">
          <h2>2017</h2>
          <div class="content">
            <p>∘ 나라장터 전자입찰 참여등록 (조달청)<strong>12</strong></p>
            <p>　　　∘ 직접 생산증명 확인 (7개 항목-중소기업중앙회)</p>
            <p>∘ 중소기업 확인 (중소벤처기업부)<strong>11</strong></p>
            <p>∘ 주식회사 맵마인드 설립<strong>09</strong></p>
          </div>
        </div>
      </div>
    </section>
  </article>
</template>
<script>
$(document).on('scroll',window, function(){
  var sc=$(this).scrollTop();
  if(sc>0)
  $('.year1').css({'top':'0','opacity':'1'});
  if(sc>220)
  $('.year2').css({'top':'0','opacity':'1'});
  if(sc>500)
  $('.year3').css({'top':'0','opacity':'1'});
  if(sc>800)
  $('.year4').css({'top':'0','opacity':'1'});
  if(sc>1100)
  $('.year5').css({'top':'0','opacity':'1'});
  if(sc>1400)
  $('.year6').css({'top':'0','opacity':'1'});
  
})
</script>

